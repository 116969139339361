import React, {useState, useEffect, useContext} from "react"
import { getDiscountPrice, getFirstPrice } from "../../../helpers/product";

export const useProductPriceState = (props) => {

    const { product, userGroupDiscount, currency } = props
    const [productPrice, setProductPrice] = useState(0)
    const [showSellPrice, setShowSellPrice] = useState(false)
    const [productDiscount, setProductDiscount] = useState(0)
    const [discountedPrice, setDiscountedPrice] = useState(0)
    const [finalProductDiscPrice, setFinalProductDiscPrice] = useState(0)
    const [discountType, setDiscountType] = useState("")
    const [finalProductPrice, setFinalProductPrice] = useState(0)
    const [finalDiscountedPrice, setFinalDiscountedPrice] = useState(0)
    const [offerPrice, setOfferPrice] = useState(0)
    useEffect(() => {
        if(product && currency) {
            const todayDate = new Date().toISOString();
            if (todayDate >= product?.startDate && todayDate <= product?.endDate) {
                if (product?.makeProductSale) {
                    setShowSellPrice(true)
                }
            } else if (product?.startDate === "" || product?.startDate === undefined) {
                if (product.makeProductSale) {
                    setShowSellPrice(true)
                }
            }


            const productPrice = product.price ? product.price : 0;
            const productDiscount = product.discount ? product.discount : 0;
            const discountedPrice = getDiscountPrice(productPrice, productDiscount);


            let finalProductDiscPrice = 0;
            let discountType = ""
            let finalProductPrice = getFirstPrice(product, product.decimalsToShow || 2);
            const finalDiscountedPrice = +(discountedPrice * currency.currencyRate).toFixed(product.decimalsToShow || 2);
            let offerPrice = 0;

            if (product.sellingPrice) {
                offerPrice = product.sellingPrice
            }
            if (product.discountType === "0" || product.discountType === "1") {
                discountType = product.discountType
            }

            if (Number(userGroupDiscount > 0) && Number(finalProductPrice) > 0) {
                finalProductPrice = Number(finalProductPrice) - (Number(finalProductPrice) * Number(userGroupDiscount) / 100)
                if(finalProductPrice < 0) {
                    finalProductPrice = 0
                }
            }

            if (discountType === "1" && Number(finalProductPrice) > 0) {
                if (offerPrice > 0) {
                    finalProductDiscPrice = Number(finalProductPrice) - Number(offerPrice)
                    if(finalProductDiscPrice < 0) {
                        finalProductDiscPrice = 0
                    }
                }
            } else if (discountType === "0" && Number(finalProductPrice) > 0) {
                finalProductDiscPrice = Number(finalProductPrice) - (Number(finalProductPrice) * Number(offerPrice) / 100)
                if(finalProductDiscPrice < 0) {
                    finalProductDiscPrice = 0
                }
            }
            setDiscountType(discountType)
            setProductPrice(productPrice)
            setFinalProductDiscPrice(finalProductDiscPrice)
            setFinalProductPrice(finalProductPrice)
            setFinalDiscountedPrice(finalDiscountedPrice)
            setOfferPrice(offerPrice)
            setDiscountedPrice(discountedPrice)
            setProductDiscount(productDiscount)

        }
    }, [product, currency])


    return {
        productPrice,
        showSellPrice,
        productDiscount,
        discountedPrice,
        finalProductDiscPrice,
        discountType,
        finalProductPrice,
        finalDiscountedPrice,
        offerPrice
    }
}
