import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import PortalImageDescription from '../../wrappers/product/PortalImageDescription'
import PortalImageCondensed from "../../wrappers/product/PortalImageCondensed";
import { AuthContext } from '../../common/Auth'
import ProductImageDescriptionPromotional from "../../wrappers/product/ProductImageDescriptionPromotional";
import ProductAttributeImageDescription from "../../wrappers/product/ProductAttributeImageDescription";
import ProductHatsImageDescription from "../../wrappers/product/ProductHatsImageDescription";
import ProductImageDescription from "../../wrappers/product/ProductImageDescription";
import { fetchProductStock } from "../../redux/actions/productActions";
import { useDispatch } from "react-redux";

function ProductModalContent(props) {
  const {
		product,
		kitConfig,
    hideImage
  } = props

  const [productLayout, setProductLayout] = useState('')
  const [productLayoutMain, setProductLayoutMain] = useState('')
  const {
    themeSettingsData,
    userGroupDiscount,
  } = useContext(AuthContext)
  const [productSettings, setProductSettings] = useState(null)
  const dispatch = useDispatch();

  useEffect(() => {
    if (themeSettingsData?.length > 0) {
      themeSettingsData.map((i) => {
        if (i.settingsName === 'theme' && i.values[0].productLayout !== '') {
          setProductLayoutMain(i.values[0].productLayout)
        }
        if (i.settingsName === 'productpageSettings') {
          if (i?.values?.[0]) {
            setProductSettings(i.values[0])
          }
        }
      })
    }
  }, [themeSettingsData])

  useEffect(() => {
    if (!!product?.productLayout ) {
      setProductLayout(product.productLayout);
    } else {
        setProductLayout(productLayoutMain)
    }
  }, [product, productLayoutMain])

  useEffect(() => {
    if(product?.id) {
      dispatch(fetchProductStock(product.id))
    }
  }, [product])

  let galleryType = null;
	let effectiveProductLayout = 'product-tab'
	if (kitConfig) {
		effectiveProductLayout = 'product-tab';
	} else if (productLayout === 'product-tab-left') {
    galleryType = 'leftThumb';
  } else if (productLayout === 'product-tab-right') {
    galleryType = 'rightThumb';
  } else if (productLayout === 'product-fixed-image') {
    galleryType = 'fixedImage';
  } else if (['product-portal', 'product-condensed', 'promotional-product', 'stepper', 'decoration-expanded'].includes(productLayout)) {
		effectiveProductLayout = productLayout;
	}

  return (
    <>
        <div className="modal-body">
					{effectiveProductLayout === 'product-tab' && (
            <div className="modalportal">
              <ProductImageDescription product={product} galleryType={galleryType} kitConfig={kitConfig} hideImage={hideImage} isModal={true}/>
            </div>
          )}
          {effectiveProductLayout === 'product-portal' && (
            <div className="modalportal">
              <PortalImageDescription product={product} isModel={true}/>
            </div>
          )}
          {effectiveProductLayout === 'product-condensed' && (
            <div className="modalportal">
              <PortalImageCondensed product={product} spaceTopClass="pt-100" spaceBottomClass="pb-100" isModel = {true}/>
            </div>
          )}
          {effectiveProductLayout === 'promotional-product' && (
            <div className="modalportal">
              <ProductImageDescriptionPromotional product={product} spaceTopClass="pt-100" spaceBottomClass="pb-100" isModel = {true}/>
            </div>
          )}
          {effectiveProductLayout === 'stepper' && (
            <div className="modalportal">
              <ProductAttributeImageDescription product={product} productSettings = {productSettings} userGroupDiscount={userGroupDiscount} isModel = {true}/>
            </div>
          )}
          {effectiveProductLayout === 'decoration-expanded' ? 
            <div className="modalportal">
              <ProductHatsImageDescription product={product} productSettings = {productSettings} userGroupDiscount={userGroupDiscount} isModel = {true}/>
            </div> : ''
          }
        </div>
    </>
  )
}

ProductModalContent.propTypes = {
  product: PropTypes.object,
  kitConfig: PropTypes.object,
};

export default ProductModalContent;
