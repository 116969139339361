import * as productsAPIUtil from "../../util/products/productsAPIUtil";
import * as advanceAPIUtil from "../../util/advance/advanceAPIUtil";
import { decorationVendorsBody } from "../../common/AdvanceBodies";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const IS_APICOUNT = "IS_APICOUNT";
export const UPDATE_PRODUCT_COLORS = "UPDATE_PRODUCT_COLORS";
export const UPDATE_PRODUCT_TAGS = "UPDATE_PRODUCT_TAGS";
export const UPDATE_PRODUCT_CATEGORIES = "UPDATE_PRODUCT_CATEGORIES";
export const UPDATE_PRODUCT_SIZES = "UPDATE_PRODUCT_SIZES";
export const UPDATE_PRODUCT_QUERY = "UPDATE_PRODUCT_QUERY";
export const UPDATE_CURRENT_PRODUCT = "UPDATE_CURRENT_PRODUCT";
export const FETCH_ERROR = "FETCH_ERROR";
export const RECEIVE_FEATURED_PRODUCTS = "RECEIVE_FEATURED_PRODUCTS";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const RECEIVE_TAG_PRODUCTS = "RECEIVE_TAG_PRODUCTS";
export const RECEIVE_DECORATION_LOCATIONS = "RECEIVE_DECORATION_LOCATIONS";
export const RECEIVE_CARTWORKS_LIST = "RECEIVE_CARTWORKS_LIST";
export const RECEIVE_CARTWORKS_LIST_BYID = 'RECEIVE_CARTWORKS_LIST_BYID';
export const RECEIVE_CARTWORKS_CARTLIST = "RECEIVE_CARTWORKS_CARTLIST";
export const RECEIVE_CARTWORKS_LIST_CARTBYID = 'RECEIVE_CARTWORKS_LIST_CARTBYID';
export const RECEIVE_ART_GLOBAL_IND = 'RECEIVE_ART_GLOBAL_IND';
export const RECEIVE_ART_DECORATORS_TYPE_LIST = 'RECEIVE_ART_DECORATORS_TYPE_LIST';
export const RECEIVE_DECORATORS_VENDORS_LIST = 'RECEIVE_DECORATORS_VENDORS_LIST';
export const RECEIVE_PRODUCT_STOCK = 'RECEIVE_PRODUCT_STOCK';
export const RECEIVE_ARTWORK_CATEGORIES_RESTRICTION = 'RECEIVE_ARTWORK_CATEGORIES_RESTRICTION';

export const isApiCount = (apiCount) => ({
    type: IS_APICOUNT,
    apiCount,
})

export const isLoading = (loading) => ({
    type: IS_LOADING,
    loading,
})
export const sendFetchError = () => ({
    type: FETCH_ERROR,
})

export const receiveFeaturedProducts = (data, count, meta) => ({
    type: RECEIVE_FEATURED_PRODUCTS,
    payload: data,
    count,
    meta,
})
export const receivetagProducts = (data, count, meta, filters) => ({
    type: RECEIVE_TAG_PRODUCTS,
    payload: data,
    count,
    meta,
    filters,
})
export const receiveProducts = (data, count, meta, apiCount) => ({
    type: RECEIVE_PRODUCTS,
    payload: data,
    count,
    meta,
    apiCount,
})

export const setColors = (productColorsSelected) => ({
    type: UPDATE_PRODUCT_COLORS,
    productColorsSelected,
})

export const setTagsProducts = (productTagsSelected) => ({
    type: UPDATE_PRODUCT_TAGS,
    productTagsSelected,
})

export const setCategoriesProducts = (productCategoriesSelected) => ({
    type: UPDATE_PRODUCT_CATEGORIES,
    productCategoriesSelected,
})

export const setSizesProduct = (productSizesSelected) => ({
    type: UPDATE_PRODUCT_SIZES,
    productSizesSelected,
})

export const setProductSearchQuery = (productQuery) => ({
    type: UPDATE_PRODUCT_QUERY,
    productQuery,
})

export const setCurrentProduct = (currentProduct) => ({
    type: UPDATE_CURRENT_PRODUCT,
    currentProduct,
})
export const setProductStock = (productStock) => ({
    type: RECEIVE_PRODUCT_STOCK,
    productStock,
})

export const setArtworksCategoriesLocationRestriction = (artworkCategoriesRestriction) => ({
    type: RECEIVE_ARTWORK_CATEGORIES_RESTRICTION,
    payload: artworkCategoriesRestriction,
})

export const fetchFeaturedProducts = (
    limit = 50,
    offset = 0,
    order = 'productName',
    orient = 'asc',
    filters = [],
) => (dispatch) => {
    productsAPIUtil
        .fetchFeaturedProducts(limit, offset, order, orient, filters)
        .then((res) => {
            const response = res.data
            const listData = response.results.map((dataArray) => {
                return dataArray
            })
            dispatch(
                receiveFeaturedProducts(
                    listData,
                    response.meta.page_count,
                    response.meta,
                ),
            )
        })
        .catch((error) => {
            dispatch(isLoading(false))
            dispatch(sendFetchError())
        })
}
export const getTagProducts =
    (
        limit = 50,
        offset = 0,
        order = 'productName',
        orient = 'asc',
        filters = [],
    ) => (dispatch) => {
        productsAPIUtil
            .getTagProducts(limit, offset, order, orient, filters)
            .then((res) => {
                const response = res.data
                const listData = response.results.map((dataArray) => {
                    return dataArray
                })
                dispatch(
                    receivetagProducts(
                        listData,
                        response.meta.page_count,
                        response.meta,
                        filters,
                    ),
                )
            })
            .catch((error) => {
                dispatch(isLoading(false))
                dispatch(sendFetchError())
            })
    }
export const fetchProducts = (
    limit = 50,
    offset = 0,
    order = 'productName',
    orient = 'asc',
    filters = [],
    apiCount
) => (dispatch) => {
    dispatch(isLoading(true));
    dispatch(
        receiveProducts(
            [],
            0,
            {},
            apiCount,
        ),
    )
    productsAPIUtil.fetchFeaturedProducts(limit, offset, order, orient, filters, apiCount).then((res) => {
        const response = res.data
        const listData = response.results.map((dataArray) => {
            return dataArray
        })
        dispatch(
            receiveProducts(
                listData,
                response.meta.page_count,
                response.meta,
                apiCount++,
            ),
        )
    })
        .catch((error) => {
            dispatch(sendFetchError())
        }).finally(() => {
            dispatch(isLoading(false));
        })
}
export const receiveDecorationLocations = (types) => ({
    type: RECEIVE_DECORATION_LOCATIONS,
    payload: types,
})
export const fetchDecorationLocations = (locations, locationsName = []) => (dispatch) => {
    productsAPIUtil.fetchDecorationLocations({limit: 1000, locations, locationsName})
        .then((res) => {
            const tempRes = { ...res?.data };
            if (tempRes?.data?.length) {
                tempRes.data = tempRes.data.filter(resa => resa?.supplierId === "");
            }
            dispatch(receiveDecorationLocations(tempRes)) 
        })
        .catch((error) => {
            dispatch(isLoading(false))
            dispatch(sendFetchError())
        })
}
export const receiveArtworks = (artworks, productId) => ({
    type: RECEIVE_CARTWORKS_LIST,
    payload: { artworks, productId },
})

export const receiveArtworksById = (artworks) => ({
    type: RECEIVE_CARTWORKS_LIST_BYID,
    payload: artworks,
})
export const receiveCartArtworks = (artworks, productId) => ({
    type: RECEIVE_CARTWORKS_CARTLIST,
    payload: { artworks, productId },
})

export const receiveArtworksCartById = (artworks) => ({
    type: RECEIVE_CARTWORKS_LIST_CARTBYID,
    payload: artworks,
})

export const receiveArtWorGlobalIndicator = (isGlobal) => ({
    type: RECEIVE_ART_GLOBAL_IND,
    payload: isGlobal,
})


const fetchArtworksAPI = (dispatch, filter, isCart) => {
    return new Promise((resolve, reject) => {
      productsAPIUtil.fetchArtworksById(filter)
        .then((res) => {
          const artworksById = res.data?.artworkListById?.data || [];
          const artworks = res.data?.artworkList?.data || [];
          const isGlobal = res.data?.isGlobal || false;
          if (isCart) {
            dispatch(receiveArtworksCartById(artworksById));
            dispatch(receiveCartArtworks(artworks, filter.product_id));
          } else {
            dispatch(receiveArtworks(artworks, filter.product_id));
            dispatch(receiveArtworksById(artworksById));
          }
          dispatch(isLoading(false));
          dispatch(receiveArtWorGlobalIndicator(isGlobal));
          resolve([...artworksById, ...artworks]);
        })
        .catch((error) => {
          dispatch(isLoading(false));
          dispatch(sendFetchError());
          reject(error);
        });
    });
  };
  
  export const fetchArtworksById = (filter) => (dispatch) => {
    return fetchArtworksAPI(dispatch, filter, false);
  };
  
  export const fetchArtworksCartById = (filter) => (dispatch) => {
    return fetchArtworksAPI(dispatch, filter, true);
  };

export const receiveDecoratorTypes = (types) => ({
    type: RECEIVE_ART_DECORATORS_TYPE_LIST,
    payload: types,
});
export const getDecoratorTypes = () => dispatch => {
    productsAPIUtil.getDecoratorTypes()
        .then(res => {
            dispatch(isLoading(false));
            dispatch(receiveDecoratorTypes(res.data))
        }).catch(error => {
            dispatch(isLoading(false));
            dispatch(sendFetchError());
        });
}

export const getDecoratorVendors = () => dispatch => {
    advanceAPIUtil.customAdvanceCall(decorationVendorsBody)
        .then(res => {
            dispatch(isLoading(false));
            dispatch(receiveDecoratorVendors(res.data))
        }).catch(error => {
            dispatch(isLoading(false));
            dispatch(sendFetchError());
        });
}
export const receiveDecoratorVendors = (types) => ({
    type: RECEIVE_DECORATORS_VENDORS_LIST,
    payload: types,
});

export const fetchProductStock = (productId) => dispatch => {
    dispatch(setProductStock(null))
    productsAPIUtil.getProductStock(productId).then(inventoryData => {
        if (inventoryData && inventoryData.status === 201) {
            dispatch(setProductStock({
                inventory: inventoryData.data.inventory,
                dstInventory: inventoryData.data.dtsInventory.data,
            }))
        }
    }).catch(err => {
        console.log(err)
    })
}

export const fetchArtworksCategoriesLocationRestriction = (categories) => dispatch => {
    dispatch(setArtworksCategoriesLocationRestriction([]))
    productsAPIUtil.getCategoriesArtworkRestriction(categories).then(response => {
        if(response && response.status === 201) {
            dispatch(setArtworksCategoriesLocationRestriction(response.data))
        }
    })
}