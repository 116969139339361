export const paymentBody = {
    method: 'payment-list',
    data: {},
}

export const taxBody = {
    method: 'tax-rate',
    data: {},
}

export const orderListBody = {
    method: 'order-list',
    data: {},
}

export const countryListBody = {
    method: 'country-list',
    data: {},
}

export const addOnChargesSUBody = {
    method: 'additional-charges',
    data: {
        'term[itemCode]': 'SU',
        limit: 1000,
    },
}

export const addOnChargesPersBody = {
    method: 'additional-charges',
    data: {
        'term[itemCode]': 'PERS',
        'term[status]': 1,
    },
}
export const decorationVendorsBody = {
    method: 'decorator-vendors',
    data: {},
}

export const orderDetailBody = {
    method: 'order-detail',
    data: {
        source: 'cp'
    },
    pathParam: undefined,
}

export const orderPaymentsBody = {
    method: 'order-payments',
    data: {
        oId: undefined
    }
}

export const createArtworkBody = {
    method: 'artworks',
    data: {}
}

export const contactBody = {
    method: 'contact-id',
}