import React, { useContext, useEffect, useState } from "react";
import { numberWithCommas2, WithCommas } from "../../../helpers/product";
import { AuthContext } from "../../../common/Auth";
import { useProductPriceToShow } from "../../../components/product/state/useProductPriceToShow";

const PrintablePriceTable = (props) => {
    const {
        usePointsAsCurrency } = useContext(AuthContext)
    const {
        getPointsFromValue,
        getPointsValue
    } = useProductPriceToShow({})

    const priceTable = props.priceTable;
    const product = props.product;
    const isModel = !!props?.isModel;
    const currency = props.currency;
    const decimalsToShow = props.decimalsToShow;
    const doNotCalculateAtOrder = props.doNotCalculateAtOrder;
    const islayout = props?.islayout ?? 0;
    const userGroupDiscount = props.userGroupDiscount;
    let showSellPrice = props?.showSellPrice
    let layout = props?.layout
    let showSell = false
    let offerPrice = 0
    let discountType = ''
    let todayDate = new Date().toISOString()

    if (
        product?.showPriceBreak &&
        todayDate >= product?.startDate &&
        todayDate <= product?.endDate && 
        showSellPrice === undefined
    ) {
        showSellPrice = true
    }

    if (todayDate >= product?.startDate && todayDate <= product?.endDate) {
        if (product?.makeProductSale) {
            showSell = true
        }
    } else if (product?.startDate == '' || product?.startDate == undefined) {
        if (product?.makeProductSale) {
            showSell = true
        }
    }

    if (product?.sellingPrice) {
        offerPrice = product.sellingPrice
      }
      if (product?.discountType == '0' || product?.discountType == '1') {
        discountType = product?.discountType
      }

      
    return (
        <div className={!!!isModel ? (layout == "condense" ? "ml-1" : (layout === "tab" ? "" : "ml-3")) : ""} style={{ marginRight: islayout ? "15px" : '' }}>
            <div className={!!isModel ? "sizediv product-price-tbl mb-1 my-2" : "row sizediv product-price-tbl mb-1 my-2"} style={{ overflowX: 'auto', margin: 0 }}>
                {islayout ? <div className="col-lg sizeContainer">
                    <label className="inputQty"><b>Quantity</b></label>
                    {showSellPrice ? <label className=" bggray lblbggray" title="Price" /> : ""}
                    <label className="bggray"><b>Price</b></label>
                </div>:''}
                {priceTable.map((row, keyP) => {
                    let Price = row.salePrice
                    if (Number(userGroupDiscount > 0) && Number(Price) > 0) {
                        Price =
                            Number(Price) -
                            (Number(Price) * Number(userGroupDiscount)) / 100
                        if (Price < 0) {
                            Price = 0
                        }
                        Price = Number(Price).toFixed(2)
                    }
                    row.discountedPrice = Price
                    if (discountType == '1' && showSell && Number(row.discountedPrice) > 0) {
                        if (offerPrice > 0) {
                            row.discountedPrice = Number(row.discountedPrice) - Number(offerPrice)
                            if (row.discountedPrice < 0) {
                                row.discountedPrice = 0
                            }
                        }
                    } else if (discountType == '0' && showSell && Number(row.discountedPrice) > 0) {
                        row.discountedPrice =
                            Number(row.discountedPrice) -
                            (Number(row.discountedPrice) * Number(offerPrice)) / 100
                        if (row.discountedPrice < 0) {
                            row.discountedPrice = 0
                        }
                    } else {
                    }

                    if(islayout) {
                        return <div className="col-lg sizeContainer" key={'p-t' + keyP}>
                            <label className="inputQty"> {row.minQuantity} </label>
                              <label className={`${showSellPrice ? 'breakable-price' : ''} bggray`} title="Price">
                            {
                                doNotCalculateAtOrder == "1" ? (
                                    <>
                                    {usePointsAsCurrency
                                        ? getPointsValue(Price * row.minQuantity)
                                        : currency.currencySymbol + numberWithCommas2(Price * row.minQuantity, decimalsToShow)}
                                    </>
                                ) : (
                                    <>
                                    {usePointsAsCurrency
                                        ? getPointsValue(Price)
                                        : currency.currencySymbol + numberWithCommas2(Price, decimalsToShow)}
                                    </>
                                )
                            }
                            </label>
                            {showSellPrice ? <label className=" bggray" title="Price"> {
                                doNotCalculateAtOrder == "1" ? (
                                    <>
                                    {usePointsAsCurrency
                                        ? getPointsValue(row.discountedPrice * row.minQuantity)
                                        : currency.currencySymbol + numberWithCommas2(row.discountedPrice * row.minQuantity, decimalsToShow)}
                                    </>
                                ) : (
                                    <>
                                    {usePointsAsCurrency
                                        ? getPointsValue(row.discountedPrice)
                                        : currency.currencySymbol + numberWithCommas2(row.discountedPrice, decimalsToShow)}
                                    </>
                                )
                            }
                            </label> : ''}
                        </div>
                    } else {
                    return <div className="col-lg sizeContainer" key={'p-t' + keyP}>
                        <label className={`${showSellPrice ? 'breakable-price' : ''} bggray`} title="Price">
                            {/* {
                                usePointsAsCurrency ? getPointsValue(row.salePrice) : currency.currencySymbol + numberWithCommas2(row.salePrice,
                                    decimalsToShow )
                            } */}
                                {/* {console.log('doNotCalculateAtOrder: ', doNotCalculateAtOrder)} */}
                            {
                                // doNotCalculateAtOrder == "1" ? (
                                //     <>
                                //     {usePointsAsCurrency
                                //         ? getPointsValue(row.salePrice * row.minQuantity)
                                //         : currency.currencySymbol + numberWithCommas2(row.salePrice * row.minQuantity, decimalsToShow)}
                                //     </>
                                // ) : (
                                    <>
                                    {usePointsAsCurrency
                                        ? getPointsValue(Price)
                                        : currency.currencySymbol + numberWithCommas2(Price, decimalsToShow)}
                                    </>
                                // )
                            }
                        </label>
                        {showSellPrice ? <label className=" bggray" title="Price"> {
                            <>
                                {usePointsAsCurrency
                                    ? getPointsValue(row.discountedPrice)
                                    : currency.currencySymbol + numberWithCommas2(row.discountedPrice, decimalsToShow)}
                            </>
                        }
                        </label> : ''}

                        <label className="inputQty"> {row.minQuantity} </label>
                    </div>
                }}) } 
            </div>
        </div>
    )
}

export default PrintablePriceTable;
