import axios from 'axios';
import {
	X_TENANT_ID,
	CART_URL
} from '../../common/ActionUrl';
import React, { useEffect, useState, useContext } from "react";
const publicIp = require("react-public-ip");

const requestOptions = {
	headers: { 'X-TENANT-ID': X_TENANT_ID, },
}

export const updateCart = async (data, publicIpLoaded) => {
	let user = localStorage.getItem('user')
		? JSON.parse(localStorage.getItem('user'))
		: ''
	const cartSession = localStorage.getItem('-') || '';
	let userId = user?.userId ? user?.userId : null;
	const ipv4 = !!publicIpLoaded ? publicIpLoaded : await publicIp.v4();
	let res = {
		"cartItems": data,
		"userId": userId ? userId : cartSession,
		"ipAddress": ipv4
	}
	return axios.post(`${CART_URL}`, res, requestOptions);
};
export const getCartById = async (user, publicIpLoaded) => {
	let userId = user?.userId ? user?.userId : null;
	if(!!!userId) {
		userId = localStorage.getItem('-') ? localStorage.getItem('-') : null;
	}
	const ipv4 = !!publicIpLoaded ? publicIpLoaded : await publicIp.v4();
	let cartItems = await axios.get(`${CART_URL}/${userId}/${ipv4}`, requestOptions);
	return cartItems?.data[0]?.cartItems && cartItems?.data[0]?.cartItems.length >0?cartItems?.data[0]?.cartItems:[];
};
export const getCartList = () => {
	return axios.get(CART_URL, requestOptions);
};
export const updateUserIdCart = async (publicIpLoaded) => {
	const ipv4 = !!publicIpLoaded ? publicIpLoaded : await publicIp.v4();
	let user = localStorage.getItem('user')
		? JSON.parse(localStorage.getItem('user'))
		: ''
	let userId = user?.userId ? user?.userId : null;
	let res = {
		"userId": userId ? userId : '',
		"ipAddress": ipv4
	}
	return axios.post(`${CART_URL}/update-userid`, res, requestOptions);
};